import React from "react";
import simpleApps from "../assets/001-speed.svg";
import enterprise from "../assets/002-cloud.svg";
import mobileApp from "../assets/002-app.svg";
import restAPI from "../assets/003-server.svg";
import webApps from "../assets/001-responsive-design.svg";
import hardwareIOT from "../assets/004-ai.svg";

const Solutions: React.FC = () => {
    return (
        <div className="solutions">
            <div className="solutions__wrapper">
                <div className="solutions__header">rozwiązania</div>
                <div className="solutions-container">
                    <div className="solutions-container__cell">
                        <div className="solutions-container__cell-photo">
                            <img src={simpleApps} className="solutions-container__cell-photo__image" />
                        </div>
                        <div className="solutions-container__cell-info">
                            <div className="solutions-container__cell-info__header">
                                proste
                                <br />
                                aplikacje
                            </div>
                            <div className="solutions-container__cell-info__text">
                                Tworzymy proste aplikacje webowe oraz mobilne.
                                Przygotowujemy animowane i interaktywne landing
                                page. Przygotowujemy projekty starając się by
                                zawsze były przyjazne użytkownikowi.
                            </div>
                        </div>
                    </div>
                    <div className="solutions-container__cell">
                        <div className="solutions-container__cell-photo">
                            <img src={enterprise} className="solutions-container__cell-photo__image" />
                        </div>
                        <div className="solutions-container__cell-info">
                            <div className="solutions-container__cell-info__header">
                                systemy
                                <br /> enterprise
                            </div>
                            <div className="solutions-container__cell-info__text">
                                Analizujemy, projektujemy, wytwarzamy a
                                następnie wdrażamy rozbudowane systemy
                                komercyjne. Od aplikacji sprzedażowych, poprzez
                                rozwiązania magazynowe aż po zarządzanie
                                maszynami w różnych lokacjach.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="solutions__header">technologie</div>
                <div className="solutions-container">
                    <div className="solutions-container__cell">
                        <div className="solutions-container__cell-photo">
                            <img src={mobileApp} className="solutions-container__cell-photo__image" />
                        </div>
                        <div className="solutions-container__cell-info">
                            <div className="solutions-container__cell-info__header">
                                aplikacje <br />
                                mobilne
                            </div>
                            <div className="solutions-container__cell-info__text">
                                Wykorzystujemy framework React Native. Dzięki
                                temu piszemy jeden kod na system iOS i Android.
                            </div>
                        </div>
                    </div>
                    <div className="solutions-container__cell">
                        <div className="solutions-container__cell-photo">
                            <img src={restAPI} className="solutions-container__cell-photo__image" />
                        </div>
                        <div className="solutions-container__cell-info">
                            <div className="solutions-container__cell-info__header">
                                Rest
                                <br /> APi
                            </div>
                            <div className="solutions-container__cell-info__text">
                                Node.js + Typescript + Express pozwala nam
                                tworzyć wydajne aplikacje backendowe .
                            </div>
                        </div>
                    </div>
                    <div className="solutions-container__cell">
                        <div className="solutions-container__cell-photo">
                            <img src={webApps} className="solutions-container__cell-photo__image" />
                        </div>
                        <div className="solutions-container__cell-info">
                            <div className="solutions-container__cell-info__header">
                                aplikacje <br />
                                webowe
                            </div>
                            <div className="solutions-container__cell-info__text">
                                Nasz Stack to React + Typescript. Do SSR
                                wykorzystujemy Next.js.
                            </div>
                        </div>
                    </div>
                    <div className="solutions-container__cell">
                        <div className="solutions-container__cell-photo">
                            <img src={hardwareIOT} className="solutions-container__cell-photo__image" />
                        </div>
                        <div className="solutions-container__cell-info">
                            <div className="solutions-container__cell-info__header">
                                hardware <br /> iot
                            </div>
                            <div className="solutions-container__cell-info__text">
                                Do cięższych zadań używamy RaspberryPi, a do
                                interaktywnych akcji zaprzęgamy Arduino.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Solutions;
