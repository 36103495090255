import React from "react";

const About: React.FC = () => {
    return (
        <div className="about">
            <div className="about__wrapper">
                <div className="about__left">
                    <div className="about__header paddingbottom">o nas</div>
                    <div className="about__text paddingbottom">
                        Tworzymy oprogramowanie od ponad 10lat. W tym czasie
                        przeszliśmy od aplikacji tworzonych w PHP i opartych na
                        hostingi do systemów działających w chmurze.
                        Wykorzystujemy najnowsze technologie frontendowe - React
                        i React Native - które znacznie przyspieszają tworzenie
                        aplikacji zarówno webowych jak i mobilnych.
                    </div>
                </div>
                <div className="about__right">
                    <div className="about__row">
                        <div className="about__header row">10</div>
                        <div className="about__text row">
                            lat doświadczenia <br /> w tworzeniu aplikacji{" "}
                            <br />
                            internetowych
                        </div>
                    </div>
                    <div className="about__row">
                        <div className="about__header row">12</div>
                        <div className="about__text row">
                            stale utrzymywanych <br /> i rozwijanych <br />
                            systemów korporacyjnych
                        </div>
                    </div>
                    <div className="about__row">
                        <div className="about__header row">100+</div>
                        <div className="about__text row">
                            zrealizowanych <br /> projektów
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
