import React from "react";
import photo from "../assets/programmer-sketch.png";

const Start: React.FC = () => {
    return (
        <div className="start">
            <div className="start__wrapper">
                <div className="start__header">
                    <div className="start__header__primary">
                        Spersonalizowane
                        <br /> rozwiązania dla
                    </div>
                    <div className="start__header__primary secondary">
                        Twojego Biznesu
                    </div>
                    <div className="start__header__button">
                        <button className="button">sprawdź</button>
                    </div>
                </div>
                <div className="start__image"></div>
            </div>
        </div>
    );
};

export default Start;
