import React from "react";

const YourProject: React.FC = () => {
    return (
        <div className="project">
            <div className="project__wrapper">
                <div className="project__center">
                    <div className="project__header">Twój projekt</div>
                    <div className="project__text">
                        Opisz nam swój pomysł a my przedstawimy Ci nasz plan jak
                        go zrealizować i wprowadzić w życie. Podaj też swoje
                        imię oraz adres email żebyśmy wiedzieli jak się z Tobą
                        skontaktować :)
                    </div>
                    <div className="project__container">
                        <div className="project-input-row">
                            <div className="project-input-cell">
                                <div className="project-input-cell__header">
                                    imie
                                </div>
                                <input className="project-input-cell__input" />
                            </div>
                            <div className="project-input-cell">
                                <div className="project-input-cell__header">
                                    email
                                </div>
                                <input className="project-input-cell__input" />
                            </div>
                        </div>
                        <div className="project-input-row big">
                            <div className="project-input-cell__header">
                                opis
                            </div>
                            <textarea className="project-input-cell__textarea" />
                        </div>
                        <div className="project-buttons">
                            <button className="button">wyślij</button>
                        </div>
                    </div>
                    <div className="project__container">
                        <div className="project__container__header">
                            Dziękujemy za wysłanie projektu!
                        </div>
                        <div className="project__container__subheader">
                            Odezwiemy się tak szybko jak to możliwe.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default YourProject;
