import React from "react";
import Nav from "./areas/Nav";
import Start from "./areas/Start";
import About from "./areas/About";
import Solutions from "./areas/Solutions";
import YourProject from "./areas/YourProject";
import WhoTrusted from "./areas/WhoTrusted";
import Component from "./areas/Contact";
import Contact from "./areas/Contact";

interface AppState {
    lang: "pl" | "en";
}

export default class App extends React.Component<{}, AppState> {
    state: AppState = {
        lang: "pl",
    };
    render() {
        return (
            <div className="router-home">
                <Nav />
                <Start />
                <About />
                <Solutions />
                <YourProject />
                <WhoTrusted />
                <Contact />
            </div>
        );
    }
}
