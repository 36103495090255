import { useHistory } from "react-router-dom";

export function useGoToSection() {
    const history = useHistory();
    return (id: string, mode?: "smooth" | "auto") => {
        history.push("/Start/#" + id);
        const section = document.getElementById(id);
        if (!section) return;
        window.scrollTo({ top: section.offsetTop - 120, behavior: mode || "smooth" });
    };
}
