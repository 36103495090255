import React from "react";

const Contact: React.FC = () => {
    return (
        <div className="contact">
            <div className="contact__wrapper">
                <div className="contact__center">
                    <div className="contact__header">kontakt</div>
                    <div className="contact__text bigpadding">
                        Z chęcią odpowiemy na wszystkie pytania.
                    </div>
                    <div className="contact__text">alex@nexcode.pl</div>
                    <div className="contact__text">+48 697 342 402</div>
                    <div className="contact__footer">
                        Copyright Nexcode Alexander Stefański
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact
