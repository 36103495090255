import React from "react";
import ReactDOM from "react-dom";

import "./scss/index.scss";

import App from "./App";

declare global {
    const APP_CONFIG: {
        API_URL: string;
        COGNITO: {
            POOL_ID: string;
            CLIENT_ID: string;
        };
    };
}

ReactDOM.render(<App />, document.getElementById("root"));
