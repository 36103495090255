import React, { useState, useContext } from "react";
import { useGoToSection } from "../utils/GoToSection";
import logo from "../assets/logo.svg";

interface State {
    isOpen: boolean;
}

const Nav: React.FC = () => {
    const [isOpen, isOpenChange] = useState(false);

    const goToStartSection = useGoToSection();

    function changeState() {
        isOpenChange((p) => !p);
    }

    return (
        <div className={`_nav ${isOpen ? "is-open" : ""}`}>
            <div className="_nav__center">
                <div className="_nav__logo">
                    <img src={logo} className="_nav__logo-img" />
                </div>
                <div className="_nav__right">
                    <div
                        className="_nav__right-button"
                        onClick={() => changeState()}
                    >
                        {isOpen ? "close" : "notes"}
                    </div>
                </div>
                <div className="_nav__links">
                    <div className="_nav__link">O nas</div>
                    <div className="_nav__link">Rozwiązania</div>
                    <div className="_nav__link">Twój projekt</div>
                    <div className="_nav__link">Kontakt</div>
                    {/* <div className="_nav__links-right">
                        <div className="_nav__link-language-switch">
                            <button className="_nav__link lang selected">
                                PL
                            </button>
                            |
                            <button className="_nav__link lang ">EN</button>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Nav;
