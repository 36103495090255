import React from "react";
import Santander from "../assets/santander.svg";
import Philips from "../assets/philips.svg";
import Schneider from "../assets/schneider.svg";
import Lilly from "../assets/lilly.svg";
import Canon from "../assets/canon.svg";
import ge from "../assets/ge.svg";

const WhoTrusted: React.FC = () => {
    return (
        <div className="whotrusted">
            <div className="whotrusted__wrapper">
                <div className="whotrusted__center">
                    <div className="whotrusted__header">zaufali nam</div>
                    <div className="whotrusted__container">
                        <div className="whotrusted__container__cell">
                            <img
                                src={Santander}
                                className="whotrusted__container__cell-img"
                            />
                        </div>
                        <div className="whotrusted__container__cell">
                            <img
                                src={Philips}
                                className="whotrusted__container__cell-img"
                            />
                        </div>
                        <div className="whotrusted__container__cell">
                            <img
                                src={Schneider}
                                className="whotrusted__container__cell-img"
                            />
                        </div>
                        <div className="whotrusted__container__cell">
                            <img
                                src={ge}
                                className="whotrusted__container__cell-img"
                            />
                        </div>
                        <div className="whotrusted__container__cell">
                            <img
                                src={Lilly}
                                className="whotrusted__container__cell-img"
                            />
                        </div>
                        <div className="whotrusted__container__cell">
                            <img
                                src={Canon}
                                className="whotrusted__container__cell-img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhoTrusted;
